import styles from "./header.module.css";
import logo from "../../assets/images/logo.png";
import { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    window.matchMedia("(min-width: 768px)").addEventListener("change", (e) => {
      setMatches(e.matches);
      setToggle(false);
    });
  }, [matches]);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const collapseMenu = () => {
    if (toggle === true) setToggle(false);
  };
  return (
    <nav className={styles["header-container"]}>
      <div className={styles["nav-container"]}>
        <HashLink to="/#home">
          <img src={logo} alt="atc-logo" className={styles["logo"]} />
        </HashLink>
        <ul className={toggle ? styles["menu"] : styles["nav-list"]}>
          <li className={styles["nav-list-element"]}>
            <HashLink smooth to="/#home" onClick={collapseMenu}>
              Ana Sayfa
            </HashLink>
          </li>
          <li className={styles["nav-list-element"]}>
            <HashLink smooth to="/#topPhones" onClick={collapseMenu}>
              Öne Çıkanlar
            </HashLink>
          </li>
          <li className={styles["nav-list-element"]}>
            <HashLink
              className="dropdown-toggle"
              smooth
              to="/#home"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Telefonlar
            </HashLink>
            <ul className="dropdown-menu bg-secondary">
              <li>
                <HashLink className="dropdown-item bg-secondary" to="/buyPhone">
                  Telefon Al
                </HashLink>
              </li>
              <li>
                <HashLink className="dropdown-item disabled" to="/#">
                  Telefon Sat - Yakında
                </HashLink>
              </li>
            </ul>
          </li>
          <li className={styles["nav-list-element"]}>
            <HashLink smooth to="/#aboutUs" onClick={collapseMenu}>
              Hakkımızda
            </HashLink>
          </li>
          <li className={styles["nav-list-element"]}>
            <HashLink smooth to="/#contact" onClick={collapseMenu}>
              İletişim
            </HashLink>
          </li>
        </ul>
        <div className={styles["hamburger-container"]}>
          <div
            className={
              toggle ? styles["hamburger-toggled"] : styles["hamburger"]
            }
            onClick={handleToggle}
          >
            <div className={styles["line-1"]}></div>
            <div className={styles["line-2"]}></div>
            <div className={styles["line-3"]}></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
