import React from "react";
import styles from "./topPhones.module.css";
import { Fade } from "react-awesome-reveal";
import axios from "axios";
import { useEffect, useState } from "react";
import PhoneCard from "../../components/phoneCard/phoneCard";
import PhonePagination from "../../components/pagination/pagination";

const TopPhones = () => {
  const [phones, setPhones] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [phonesPerPage] = useState(20);

  useEffect(() => {
    fetchTopPhones();
  }, []);

  const indexOfLastPhone = currentPage * phonesPerPage;
  const indexOfFirstPhone = indexOfLastPhone - phonesPerPage;
  const currentPhones = phones.slice(indexOfFirstPhone, indexOfLastPhone);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const fetchTopPhones = () => {
    axios
      .get("https://admin.atcphonemarkt.com/backend/api/getPhones.php")
      .then((response) => {
        setPhones(response.data.filter((phone) => phone.favori === "1"));
      });
  };

  return (
    <div className={styles["top-phones-container"]} id="topPhones">
      <Fade direction="up" cascade={true} triggerOnce={true}>
        <h1 className={styles["title"]}>Öne Çıkanlar</h1>
        <div className={styles["phones-container"]}>
          <div className={styles["data-container"]}>
            {currentPhones?.map((phone, idx) => {
              return <PhoneCard phone={phone} key={idx} />;
            })}
          </div>
          <div className={styles["pagination-container"]}>
            <PhonePagination
              phonesPerPage={phonesPerPage}
              totalPhones={phones.length}
              paginate={paginate}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default TopPhones;
