import React from "react";
import wp from "../../assets/icons/wp-ic.webp";
import styles from "./wp.module.css";

const Wp = () => {
  return (
    <a href="https://wa.me/5064410807" target="_blank" rel="noopener noreferrer">
      <img src={wp} alt="wp-ic" className={styles["logo"]}></img>
    </a>
  );
};

export default Wp;
