export const markalar = ["Apple", "Samsung"];

export const sıralamalar = ["Ucuzdan Pahalıya", "Pahalıdan Ucuza"];

export const modeller = {
  Apple: [
    "iPhone 6",
    "iPhone 6 Plus",
    "iPhone 6s",
    "iPhone 6s Plus",
    "iPhone SE [1. Nesil]",
    "iPhone SE [2. Nesil]",
    "iPhone SE [3. Nesil]",
    "iPhone 7",
    "iPhone 7 Plus",
    "iPhone 8",
    "iPhone 8 Plus",
    "iPhone X",
    "iPhone XS",
    "iPhone XS Max",
    "iPhone XR",
    "iPhone 11",
    "iPhone 11 Pro",
    "iPhone 11 Pro Max",
    "iPhone 12",
    "iPhone 12 Pro",
    "iPhone 12 Pro Max",
    "iPhone 12 Mini",
    "iPhone 13",
    "iPhone 13 Pro",
    "iPhone 13 Pro Max",
    "iPhone 13 Mini",
    "iPhone 14",
    "iPhone 14 Plus",
    "iPhone 14 Pro",
    "iPhone 14 Pro Max",
    "iPhone 15",
    "iPhone 15 Plus",
    "iPhone 15 Pro",
    "iPhone 15 Pro Max",
  ],
  Samsung: [
    "Samsung Galaxy A54",
    "Samsung Galaxy S23 Ultra",
    "Samsung Galaxy S23",
    "Samsung Galaxy A34",
    "Samsung Galaxy A14",
    "Samsung Galaxy S10",
    "Samsung Galaxy S22 Ultra",
    "Samsung Galaxy S22",
    "Samsung Galaxy A53",
    "Samsung Galaxy S21",
    "Samsung Galaxy A12",
    "Samsung Galaxy A04",
    "Samsung Galaxy A13",
    "Samsung Galaxy S20",
  ],
};

export const renkler = [
  "Beyaz",
  "Siyah",
  "Gümüş",
  "Gri",
  "Altın",
  "Kırmızı",
  "Mavi",
  "Mor",
  "Yeşil",
  "Pembe",
  "Sarı",
  "Deep Purple",
  "Sierra Blue",
  "Pasific Blue",
  "Midnight",
  "Natural Titanium",
  "Blue Titanium",
  "Black Titanium",
];

export const depolama = [
  "16 GB",
  "32 GB",
  "64 GB",
  "128 GB",
  "256 GB",
  "512 GB",
  "1 TB",
];
