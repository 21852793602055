import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PhoneCard from "../../components/phoneCard/phoneCard";
import PhonePagination from "../../components/pagination/pagination";
import Header from "../../layouts/header/header";
import video from "../../assets/video/bg-video.mp4";
import poster from "../../assets/images/bg-poster.webp";
import styles from "./buyPhone.module.css";
import { AppLevelContext } from "../../context/appLevelContext";
import {
  markalar,
  modeller,
  renkler,
  depolama,
  sıralamalar,
} from "../../constants/constants";

const BuyPhone = () => {
  const { totalPhones } = useContext(AppLevelContext);
  const [phones, setPhones] = useState(totalPhones.slice(0, 8));
  const [phoneNumber, setPhoneNumber] = useState(totalPhones.length);
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggle, setToggle] = useState(true);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const [filters, setFilters] = useState({
    minPrice: searchParams.get("minPrice") || null,
    maxPrice: searchParams.get("maxPrice") || null,
    marka: searchParams.get("marka") || null,
    modeller: searchParams.get("modeller") || [],
    renkler: searchParams.get("renkler") || [],
    depolama: searchParams.get("depolama") || [],
    sıralama: searchParams.get("sıralama") || null,
  });

  const paginate = (pageNumber) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      page: pageNumber.toString(),
    }));

    const phonesPerPage = 20;
    const indexOfLastPhone = pageNumber * phonesPerPage;
    const indexOfFirstPhone = indexOfLastPhone - phonesPerPage;

    const filteredPhones = applyFilters(totalPhones, filters);
    const sortedPhones = sortPhones(filteredPhones, filters.sıralama);

    setPhones(sortedPhones.slice(indexOfFirstPhone, indexOfLastPhone));
  };

  useEffect(() => {
    const page = parseInt(searchParams.get("page")) || 1;
    const indexOfLastPhone = page * 20;
    const indexOfFirstPhone = indexOfLastPhone - 20;

    const hasSearchParams = searchParams.toString() !== "";
    const isFiltering = Object.values(filters).some((filter) =>
      Array.isArray(filter) ? filter.length > 0 : filter !== null
    );

    const updatedSearchParams = new URLSearchParams(searchParams);

    if (hasSearchParams) {
      const filteredPhones = applyFilters(totalPhones, filters);
      const sortedPhones = sortPhones(filteredPhones, filters.sıralama);
      setPhones(sortedPhones.slice(indexOfFirstPhone, indexOfLastPhone));
      setPhoneNumber(sortedPhones.length);

      if (isFiltering && page > 1) {
        updatedSearchParams.set("page", "1");
      }
    } else {
      setPhones(totalPhones.slice(indexOfFirstPhone, indexOfLastPhone));
      setPhoneNumber(totalPhones.length);

      for (const key in filters) {
        if (
          key !== "page" &&
          (Array.isArray(filters[key])
            ? filters[key].length === 0
            : filters[key] === null)
        ) {
          updatedSearchParams.delete(key);
        }
      }
    }

    for (const key in filters) {
      if (
        key !== "page" &&
        (Array.isArray(filters[key])
          ? filters[key].length > 0
          : filters[key] !== null)
      ) {
        updatedSearchParams.set(
          key,
          Array.isArray(filters[key]) ? filters[key].join(",") : filters[key]
        );
      }
    }

    setSearchParams(updatedSearchParams);

    window.matchMedia("(min-width: 768px)").addEventListener("change", (e) => {
      setMatches(e.matches);
      setToggle(true);
    });
  }, [searchParams, filters, matches, totalPhones, setSearchParams]);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const collapseMenu = () => {
    if (toggle === false) setToggle(true);
  };

  const applyFilters = (phones, filters) => {
    return phones
      .filter(
        (phone) => !filters.minPrice || Number(phone.fiyat) >= filters.minPrice
      )
      .filter(
        (phone) => !filters.maxPrice || Number(phone.fiyat) <= filters.maxPrice
      )
      .filter((phone) => !filters.marka || phone.marka === filters.marka)
      .filter(
        (phone) =>
          filters.modeller.length === 0 ||
          filters.modeller.includes(phone.model)
      )
      .filter(
        (phone) =>
          filters.renkler.length === 0 || filters.renkler.includes(phone.renk)
      )
      .filter(
        (phone) =>
          filters.depolama.length === 0 ||
          filters.depolama.includes(phone.depolama)
      );
  };

  const sortPhones = (phones, sıralama) => {
    if (sıralama === "Ucuzdan Pahalıya") {
      return phones.sort((a, b) => a.fiyat - b.fiyat);
    } else if (sıralama === "Pahalıdan Ucuza") {
      return phones.sort((a, b) => b.fiyat - a.fiyat);
    } else {
      return phones;
    }
  };

  const handleFilterChange = (event) => {
    const { name, value, type, checked } = event.target;

    const updatedValue =
      type === "checkbox"
        ? checked
          ? [...filters[name], value]
          : filters[name].filter((item) => item !== value)
        : value;

    setFilters({ ...filters, [name]: updatedValue });

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set(
      name,
      Array.isArray(updatedValue) ? updatedValue.join(",") : updatedValue
    );
    setSearchParams(updatedSearchParams);
  };

  const resetFilters = () => {
    setFilters({
      minPrice: null,
      maxPrice: null,
      marka: null,
      modeller: [],
      renkler: [],
      depolama: [],
      sıralama: null,
    });

    const updatedSearchParams = new URLSearchParams();
    setSearchParams(updatedSearchParams);
    setPhoneNumber(totalPhones.length);
  };

  return (
    <>
      <Header />
      <div className={styles["video-container"]}>
        <video
          className={styles["video"]}
          loop
          autoPlay
          muted
          playsInline
          poster={poster}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className="text-center">
        <div className={styles["home-container"]} id="home-container">
          <div
            className={
              toggle ? styles["filter-menu-toggled"] : styles["filter-menu"]
            }
            onClick={handleToggle}
          >
            <div className={styles["line-1"]}></div>
            <div className={styles["line-2"]}></div>
          </div>
          <div
            className={
              toggle
                ? styles["filter-container-toggled"]
                : styles["filter-container"]
            }
          >
            <div className="filter-accordion-container">
              <div className="accordion" id="accordion-parent">
                {/* Fiyat Filtresi */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button text-warning bg-dark ${styles["accordion-button"]}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Fiyat
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse bg-secondary collapse show"
                    data-bs-parent="#accordion-parent"
                  >
                    <div className="accordion-body">
                      <div className="form-floating mb-3">
                        <input
                          name="minPrice"
                          type="number"
                          className="form-control"
                          id="floatingMinPrice"
                          placeholder="Min. Fiyat"
                          value={filters.minPrice}
                          onChange={handleFilterChange}
                        />
                        <label htmlFor="floatingMinPrice">Min. Fiyat</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          name="maxPrice"
                          type="number"
                          className="form-control"
                          id="floatingMaxPrice"
                          placeholder="Max. Fiyat"
                          value={filters.maxPrice}
                          onChange={handleFilterChange}
                        />
                        <label htmlFor="floatingMaxPrice">Max. Fiyat</label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Marka Filtresi */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button text-warning bg-dark ${styles["accordion-button"]}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      Marka
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse text-warning bg-secondary collapse"
                    data-bs-parent="#accordion-parent"
                  >
                    <div className="accordion-body">
                      {markalar.map((marka2, idx) => (
                        <div className="form-check" key={idx}>
                          <input
                            className="form-check-input bg-warning"
                            type="radio"
                            name="marka"
                            value={marka2}
                            checked={marka2 === filters.marka ? true : false}
                            id={"flexCheckDefault" + idx}
                            onChange={handleFilterChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + idx}
                          >
                            {marka2}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Modeller Filtresi */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button text-warning bg-dark ${styles["accordion-button"]}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      Modeller
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse text-warning bg-secondary collapse"
                    data-bs-parent="#accordion-parent"
                  >
                    <div
                      className={`accordion-body ${styles["accordion-body"]}`}
                    >
                      {filters.marka ? (
                        <>
                          {modeller[filters.marka]?.map((model, idx) => {
                            return (
                              <div className="form-check" key={idx}>
                                <input
                                  name="modeller"
                                  key={idx}
                                  className="form-check-input bg-warning"
                                  type="checkbox"
                                  value={model}
                                  id={"flexCheckDefault" + idx}
                                  checked={filters.modeller.includes(model)}
                                  onChange={handleFilterChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"flexCheckDefault" + idx}
                                >
                                  {model}
                                </label>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <p className="text-warning">
                          Lütfen Önce Marka Seçiniz
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Renkler Filtresi */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button text-warning bg-dark ${styles["accordion-button"]}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      Renkler
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse text-warning bg-secondary collapse"
                    data-bs-parent="#accordion-parent"
                  >
                    <div className="accordion-body">
                      {renkler?.map((renk, idx) => {
                        return (
                          <div className="form-check" key={idx}>
                            <input
                              name="renkler"
                              key={idx}
                              className="form-check-input bg-warning"
                              type="checkbox"
                              value={renk}
                              id={"flexCheckDefault" + idx}
                              checked={filters.renkler.includes(renk)}
                              onChange={handleFilterChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"flexCheckDefault" + idx}
                            >
                              {renk}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* Depolama Filtresi */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button text-warning bg-dark ${styles["accordion-button"]}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="true"
                      aria-controls="collapseFive"
                    >
                      Depolama
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse text-warning bg-secondary collapse"
                    data-bs-parent="#accordion-parent"
                  >
                    <div className="accordion-body">
                      {depolama?.map((depolama, idx) => {
                        return (
                          <div className="form-check" key={idx}>
                            <input
                              name="depolama"
                              key={idx}
                              className="form-check-input bg-warning"
                              type="checkbox"
                              value={depolama}
                              id={"flexCheckDefault" + idx}
                              checked={filters.depolama.includes(depolama)}
                              onChange={handleFilterChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"flexCheckDefault" + idx}
                            >
                              {depolama}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* Sıralama Filtresi */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button text-warning bg-dark ${styles["accordion-button"]}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Sıralama
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse text-warning bg-secondary collapse"
                    data-bs-parent="#accordion-parent"
                  >
                    <div className="accordion-body">
                      {sıralamalar.map((sıralama2, idx) => (
                        <div className="form-check" key={idx}>
                          <input
                            className="form-check-input bg-warning"
                            type="radio"
                            name="sıralama"
                            value={sıralama2}
                            checked={
                              sıralama2 === filters.sıralama ? true : false
                            }
                            id={"flexCheckDefault" + idx}
                            onChange={handleFilterChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + idx}
                          >
                            {sıralama2}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Filtreleme Düğmeleri */}
            <div className={styles["filters-btns-container"]}>
              <button
                type="button"
                className="btn btn-outline-info"
                onClick={() => {
                  resetFilters();
                  collapseMenu();
                }}
              >
                Sıfırla
              </button>
            </div>
          </div>
          <div className={styles["phones-container"]}>
            {phones.length === 0 ? (
              <p className="text-center">Telefon bulunamadı.</p>
            ) : (
              <>
                <div className={styles["data-container"]}>
                  {phones.map((phone, idx) => (
                    <PhoneCard phone={phone} key={idx} />
                  ))}
                </div>
                <div className={styles["pagination-container"]}>
                  <PhonePagination
                    phonesPerPage={20}
                    phoneNumber={phoneNumber}
                    paginate={paginate}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyPhone;
