import React from "react";
import styles from "./igCard.module.css";
import ig from "../../assets/icons/ig-ic.webp";
import { Fade } from "react-awesome-reveal";

const IgCard = () => {
  return (
    <Fade direction="up" cascade={true} triggerOnce={true}>
      <div className={styles["card-container"]}>
        <div className={styles["ig-container"]}>
          <img src={ig} alt="ig-ic" className={styles["ig"]} />
        </div>
        <div className={styles["content-container"]}>
          <h4 className={styles["title"]}>
            Instagram'da <span className={styles["account"]}>@atc.phonemarkt</span> hesabını takip
            etmeyi unutma!
          </h4>
          <h5 className={styles["content"]}>
            Telefonlarımızı paylaştığımız instagram hesabını takip etmeyi unutmayın.
          </h5>
          <button className={styles["button"]}>
            <a
              href="https://www.instagram.com/atc.phonemarkt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Takip Et
            </a>
          </button>
        </div>
      </div>
    </Fade>
  );
};

export default IgCard;
