import React from "react";
import styles from "./home.module.css";
import video from "../../assets/video/bg-video.mp4";
import poster from "../../assets/images/bg-poster.webp";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const home = () => {
  return (
    <div className={styles["home-container"]} id="home">
      <div className={styles["video-container"]}>
        <video
          className={styles["video"]}
          loop
          autoPlay
          muted
          playsInline
          poster={poster}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className={styles["content-container"]}>
        <Fade direction="up" cascade={true} triggerOnce={true}>
          <span className={styles["span-text"]}>
            <span className={styles["span-text-title"]}>ATC</span> <br />{" "}
            <span className={styles["span-text-title"]}>phonemarkt</span> <br />{" "}
            İkinci elin güvenilir adresi.
          </span>
          <Link to="/buyPhone">
            <button className={styles["phones-btn"]}>
              <span>Telefonlar</span>
            </button>
          </Link>
        </Fade>
      </div>
    </div>
  );
};

export default home;
