import React from "react";
import styles from "./footer.module.css";
import ig from "../../assets/icons/ig-ic.webp";
import letgo from "../../assets/icons/letgo-ic.png";
import sahibinden from "../../assets/icons/sahibinden-ic.png";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import alaz from "../../assets/images/alaz.png";

const Footer = () => {
  return (
    <div className={styles["footer-container"]}>
      <div className={styles["map-container"]}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d755.5908516276211!2d30.025753075895484!3d40.75403120951402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb508c62fa35cf%3A0x74000a030867af51!2sUlus%2C%20Zafer%20Sk.%20No%3A31%2C%2041250%20Kartepe%2FKocaeli!5e0!3m2!1str!2str!4v1693916159912!5m2!1str!2str"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className={styles["content-container"]}>
        <div className={styles["contact-container"]}>
          <span className={styles["title"]}>İletişim</span>
          <p>
            Adres : Ulus, Zafer Sk. No:31, <br></br> 41250 Kartepe/Kocaeli
          </p>
          <p>Telefon : 506 041 12 34</p>
        </div>
        <div className={styles["navigation-container"]}>
          <span className={styles["title"]}>Site İçeriği</span>
          <HashLink to="/#home">Ana Sayfa</HashLink>
          <HashLink to="/#topPhones">Öne Çıkanlar</HashLink>
          <Link to="/buyPhone">Telefon Al</Link>
          <HashLink to="/#">Telefon Sat - Yakında</HashLink>
          <HashLink to="/#aboutUs">Hakkımızda</HashLink>
          <HashLink to="/#contact">İletişim</HashLink>
        </div>
        <div className={styles["social-container"]}>
          <span className={styles["title"]}>Bizi Takip Edin</span>
          <div className={styles["icons-container"]}>
            <a
              href="https://www.instagram.com/atc.phonemarkt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ig} alt="ig-ic" className={styles["social-icons"]} />
            </a>

            <a
              href="https://www.sahibinden.com/arama?userId=aroM-fVPYZs4nhvYql8Y-uw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={sahibinden}
                alt="sahibinden-ic"
                className={styles["social-icons"]}
              />
            </a>
            <a
              href="https://www.letgo.com/profile/164423659"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={letgo}
                alt="letgo-ic"
                className={styles["social-icons"]}
              />
            </a>
          </div>
          <div className={styles["alaz"]}>
            <a
              href="https://alazcreativeagency.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={alaz} width={150} alt="alaz" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
