import React, { useEffect, useState } from "react";
import axios from "axios";

export const AppLevelContext = React.createContext();

export const AppLevelProvider = ({ children }) => {
  const [totalPhones, setTotalPhones] = useState([]);

  const fetchPhones = async () => {
    try {
      const response = await axios.get(
        "https://admin.atcphonemarkt.com/backend/api/getPhones.php"
      );
      const fetchedPhones = response.data;
      setTotalPhones(fetchedPhones);
    } catch (error) {
      console.error("Error fetching phones:", error);
    }
  };
  useEffect(() => {
    fetchPhones();
  }, []);
  return (
    <AppLevelContext.Provider value={{ totalPhones }}>
      {children}
    </AppLevelContext.Provider>
  );
};
