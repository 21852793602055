import React from "react";
import styles from "./letgoCard.module.css";
import letgo from "../../assets/icons/letgo-ic.png";
import { Fade } from "react-awesome-reveal";

const LetgoCard = () => {
  return (
    <Fade direction="up" cascade={true} triggerOnce={true}>
      <div className={styles["letgo-card-container"]}>
        <div className={styles["letgo-container"]}>
          <img src={letgo} alt="letgo-ic" className={styles["letgo"]} />
        </div>
        <div className={styles["content-container"]}>
          <h4 className={styles["title"]}>
            Güncel ilanlarımızı paylaştığımız Letgo hesabımıza göz at!
          </h4>
          <button className={styles["button"]}>
            <a
              href="https://www.letgo.com/profile/164423659"
              target="_blank"
              rel="noopener noreferrer"
            >
              İncele
            </a>
          </button>
        </div>
      </div>
    </Fade>
  );
};

export default LetgoCard;
