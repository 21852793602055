import React, { useState, useEffect } from "react";
import "./phoneDetail.css";
import { useLocation, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import wp from "../../assets/icons/wp-ic.webp";
import axios from "axios";
import Header from "../../layouts/header/header";

const PhoneDetail = () => {
  const location = useLocation();
  const initialPhoneState = location.state;
  const [phone, setPhone] = useState(initialPhoneState);

  const { id } = useParams();

  useEffect(() => {
    if (!phone || phone.id !== id) {
      axios
        .get(`https://admin.atcphonemarkt.com/backend/api/getPhones.php?${id}`)
        .then((response) => {
          if (response.data.length > 0) {
            setPhone(response.data[0]);
          } else {
            console.error("Phone not found");
          }
        })
        .catch((error) => {
          console.error("Error fetching phone detail:", error);
        });
    }
  }, [id, location, phone]);

  if (!phone) {
    return (
      <div className="bg-secondary vh-100 d-flex justify-content-center align-items-center">
        <h2 className="text-white">Yükleniyor...</h2>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="bg-secondary pt-5 pb-5">
        <h1 id="title" className="text-center text-white">
          {phone.baslik}
        </h1>
        <div className="container-sm" id="detail-container-sm">
          <div className="carousel-container-sm" id="main-img-container">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div
                className="carousel-indicators"
                id="carouselIndicatorContainer"
              >
                {phone.resimler.split(",").map((resim, idx) => {
                  return (
                    <button
                      key={idx}
                      type="button"
                      id="indicatorBtn"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={idx}
                      className={idx === 0 ? "active" : ""}
                      aria-current="true"
                      aria-label={`Slide ${idx}`}
                    >
                      <div id="indicatorContainer">
                        <img
                          id="indicatorImg"
                          src={`https://admin.atcphonemarkt.com/phone-images/${resim}`}
                          alt="phone-img"
                        />
                      </div>
                    </button>
                  );
                })}
              </div>
              <div className="carousel-inner">
                {phone.resimler.split(",").map((resim, idx) => {
                  return (
                    <div
                      className={
                        idx === 0 ? "carousel-item active" : "carousel-item"
                      }
                      key={idx}
                    >
                      <img
                        src={`https://admin.atcphonemarkt.com/phone-images/${resim}`}
                        className="d-block w-100"
                        alt="phone-img"
                      />
                    </div>
                  );
                })}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>

          <div className="description-container m-3">
            <h3 className="m-1 text-start">Açıklama</h3>
            <hr />
            <div
              className="text-start mt-3"
              dangerouslySetInnerHTML={{ __html: phone.aciklama }}
            ></div>
          </div>
          <div className="phone-detail-container m-3">
            <h3 className="m-3 text-start text-white">
              Fiyat :
              <NumericFormat
                value={phone.fiyat}
                displayType="text"
                thousandSeparator="."
                decimalSeparator="false"
                suffix="₺"
                id="fiyat"
              />{" "}
            </h3>
            <hr className="horizontal-line" />
            <h3 className="m-3 text-start">Detaylar</h3>
            <hr className="horizontal-line" />
            <h5 className="m-3">
              Marka : <span>{phone.marka}</span>
            </h5>
            <h5 className="m-3">
              Model : <span>{phone.model}</span>
            </h5>
            <h5 className="m-3">
              Renk : <span>{phone.renk}</span>
            </h5>
            <h5 className="m-3">
              Depolama : <span>{phone.depolama}</span>
            </h5>
            <a
              href="https://wa.me/5064410807"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="wp-contact-button">
                <img src={wp} alt="wp-ic" className="wp-logo"></img>
                <h1 className="get-contact-text">İletişim Kur</h1>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneDetail;
