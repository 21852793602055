import styles from "./aboutUs.module.css";
import { Fade } from "react-awesome-reveal";
import React from "react";

const AboutUs = () => {
  return (
    <div className={styles["about-us-container"]} id="aboutUs">
      <Fade direction="up" cascade={true} triggerOnce={true}>
        <h1 className={styles["title"]}>Hakkımızda</h1>
        <div className={styles["content-container"]}>
          <div className={styles["comment-container"]}>
            <h5>
              "Bu platform sayesinde harika bir ikinci el iPhone buldum. Telefonun durumu harikaydı
              ve fiyatı oldukça makuldi. İşletmenin profesyonelliği ve iletişimleri harikaydı.
              Kesinlikle tekrar buradan alışveriş yaparım!"
            </h5>
            <h4>Merve K. - İstanbul</h4>
          </div>
          <div className={styles["comment-container"]}>
            <h5>
              "Yıllardır ikinci el telefon alırım, ancak bu siteyi bulduktan sonra hiçbir yere
              gitmeme gerek kalmadı. Her zaman kaliteli ürünler buluyorum ve işletme sahipleri her
              soruma hızlıca cevap veriyor. Kesinlikle tavsiye ederim!"
            </h5>
            <h4>Ahmet Y. - Ankara</h4>
          </div>
          <div className={styles["comment-container"]}>
            <h5>
              "Bu platform, ikinci el iPhone satın almak veya satmak için mükemmel bir yer. Hem
              alıcılar hem de satıcılar için güvenilir bir ara yüz sunuyorlar. Burada birçok telefon
              aldım ve her seferinde harika bir deneyim yaşadım. Teşekkür ederim!"
            </h5>
            <h4>Elif D. - İzmir</h4>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default AboutUs;
