import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from "./pagination.module.css";

function PhonePagination({ phonesPerPage, phoneNumber, paginate }) {
  const { search } = useLocation();

  const currentPage = parseInt(new URLSearchParams(search).get("page")) || 1;

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(phoneNumber / phonesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className={styles["pagination"]}>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <Link
              to={`?page=${number}`}
              className="page-link bg-secondary text-warning"
              onClick={(e) => {
                e.preventDefault();
                paginate(number);
              }}
            >
              {number}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default PhonePagination;
