import React from "react";
import styles from "./contact.module.css";
import IgCard from "../../components/igCard/igCard";
import SahibindenCard from "../../components/sahibindenCard/sahibindenCard";
import LetgoCard from "../../components/letgoCard/letgoCard";

const Contact = () => {
  return (
    <div className={styles["contact-container"]} id="contact">
      <h1 className="text-warning">İletişim</h1>
      <IgCard />
      <div className={styles["bottom-container"]}>
        <div className={styles["sahibinden"]}>
          <SahibindenCard />
        </div>
        <div className={styles["letgo"]}>
          <LetgoCard />
        </div>
      </div>
    </div>
  );
};

export default Contact;
