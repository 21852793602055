import React from "react";
import Home from "./pages/home/home.jsx";
import AboutUs from "./pages/aboutUs/aboutUs.jsx";
import Header from "./layouts/header/header.jsx";
import TopPhones from "./pages/topPhones/topPhones.jsx";
import Contact from "./pages/contact/contact.jsx";
import Wp from "./components/wp/wp.jsx";
import Footer from "./layouts/footer/footer.jsx";

const App = () => {
  return (
    <>
      <Wp />
      <Header />
      <Home />
      <TopPhones />
      <AboutUs />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
