import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import PhoneDetail from "./pages/detail/phoneDetail";
import BuyPhone from "./pages/buyPhone/buyPhone";
import SellPhone from "./pages/sellPhone/sellPhone";
import { AppLevelProvider } from "./context/appLevelContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppLevelProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/phoneDetail/:id" element={<PhoneDetail />}></Route>
          <Route path="/buyPhone" element={<BuyPhone />}></Route>
          <Route path="/sellPhone" element={<SellPhone />}></Route>
        </Routes>
      </HashRouter>
    </AppLevelProvider>
  </React.StrictMode>
);
