import React from "react";
import styles from "./sahibindenCard.module.css";
import sahibinden from "../../assets/icons/sahibinden-ic.png";
import { Fade } from "react-awesome-reveal";

const SahibindenCard = () => {
  return (
    <Fade direction="up" cascade={true} triggerOnce={true}>
      <div className={styles["sahibinden-card-container"]}>
        <div className={styles["sahibinden-container"]}>
          <img src={sahibinden} alt="fb-ic" className={styles["sahibinden"]} />
        </div>
        <div className={styles["content-container"]}>
          <h4 className={styles["title"]}>
            Güncel ilanlarımızı paylaştığımız Sahibinden hesabımıza göz at!
          </h4>
          <button className={styles["button"]}>
            <a
              href="https://www.sahibinden.com/arama?userId=aroM-fVPYZs4nhvYql8Y-uw"
              target="_blank"
              rel="noopener noreferrer"
            >
              İncele
            </a>
          </button>
        </div>
      </div>
    </Fade>
  );
};

export default SahibindenCard;
